import useTranslation from 'next-translate/useTranslation';
import styled from '@emotion/styled';

import { Promo } from '@app/api/resources/Promo';

import { color as themeColors } from '@app/themes/mubi-theme';

import MubiButtonLink from '@app/components/buttons/MubiButton/MubiButtonLink';
import PageSection from '@app/components/layout/PageSection';
import PromoBackgroundImage from '@app/components/promo/PromoBackgroundImage';
import PromoHeader from '@app/components/promo/PromoHeader';
import StandardHeadTags from '@app/components/StandardHeadTags';

const BG_IMG = `${process.env.assetPrefix}/splash/amelie_mad.jpg`;
const BG_AVG_COLOR = '#572f0f';

type PromoDisabledProps = {
  promo: Promo;
};

const PromoDisabled = ({ promo }: PromoDisabledProps) => {
  const { t } = useTranslation('promo');

  return (
    <>
      <StandardHeadTags noIndex />
      <PromoBackgroundImage
        backgroundImg={BG_IMG}
        backgroundColor={BG_AVG_COLOR}
      />
      <PromoHeader
        specialPromoSplashPage={promo.special_promo_splash_page}
        primaryColor={themeColors.white}
        showSecondaryContent={false}
      />
      <PageSection>
        <Content>
          <Title>{t('promo:promo.disabled.title')}</Title>
          <Body>{t('promo:promo.disabled.body_no_trial')}</Body>
          <Cta url="/memberships">{t('promo:promo.disabled.cta_no_trial')}</Cta>
        </Content>
      </PageSection>
    </>
  );
};

const Content = styled.div`
  position: relative;
  color: ${props => props.theme.color.white};
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  @media (min-width: ${props => props.theme.mqNew.tablet}) {
    width: 600px;
    margin: 0 auto;
  }
`;

const Title = styled.h1`
  text-transform: uppercase;
  font-weight: 500;
  font-size: 28px;
  line-height: 1.2;
  margin-bottom: 12px;

  @media (min-width: ${props => props.theme.mqNew.tablet}) {
    font-size: 36px;
    margin-bottom: 18px;
  }
`;

const Body = styled.p`
  font-size: 22px;
  line-height: 1.15;
  width: 100%;
  margin-bottom: 20px;

  @media (min-width: ${props => props.theme.mqNew.tablet}) {
    font-size: 28px;
    margin-bottom: 30px;
  }
`;

const Cta = styled(MubiButtonLink)`
  font-size: 21px;
  padding: 14px 30px;
`;

export default PromoDisabled;
